<template>
  <v-container grid-list-xl text-xs-center>
    <v-layout row wrap align-center>
      <v-flex xs12>
        <v-card-title>
          Student Management Report
          <v-spacer></v-spacer>
        </v-card-title>
        <v-form ref="form">
          <v-card-text>
            <v-row>
              <v-col v-if="allowRegionFilter" cols="12">
                <v-select v-model="selectRegion" :items="regions" item-text="Name" item-value="RegionID" label="Region" prepend-icon="mdi-earth" 
                @change="selectCentre = null"
                clearable></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col v-if="allowCentreFilter" cols="12">
                <v-select v-model="selectCentre" :items="filteredCentres" item-text="Name" item-value="CentreID" label="Campus" prepend-icon="mdi-home-outline" 
                clearable></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="studentid" prepend-icon="mdi-magnify" label="Student ID" clearable></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="givenname" prepend-icon="mdi-magnify" label="Given Name" clearable></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field v-model="surname" prepend-icon="mdi-magnify" label="Surname" clearable></v-text-field>
              </v-col> 
            </v-row>
            <v-row>
              <v-btn color="primary" class="mr-4" @click="Search" :loading="searchLoading">Search</v-btn>
            </v-row>
          </v-card-text>
        </v-form>
      </v-flex>
      <v-flex xs12 v-if="showTable">
        <v-card>
          <v-card-title>
            Results
            <v-spacer></v-spacer>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
          </v-card-title>
          <v-data-table multi-sort :headers="headers" :items="results" item-key="name" :search="search" :loading="searchLoading" loading-text="Loading... Please wait">
            <template v-slot:[`body.append`]="{headers}">
              <tr>
                <td v-for="(header,i) in headers" :key="i">
                  <div v-if="header.value == 'StudentID'">
                    <p class="subtitle-1 font-weight-bold">Total</p>
                  </div>
                  <div v-if="header.value == 'HomeworkCount'">
                    <p class="subtitle-1 text-center font-weight-bold">{{totals && totals.HomeworkCountTotal}}</p>
                  </div>
                  <div v-if="header.value == 'MarkedCount'">
                    <p class="subtitle-1 text-center font-weight-bold">{{totals && totals.MarkedCountTotal}}</p>
                  </div>
                  <div v-if="header.value == 'AwardCount'">
                    <p class="subtitle-1 text-center font-weight-bold">{{totals && totals.AwardCountTotal}}</p>
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>       
      </v-flex>
    </v-layout>
    <div class="text-center">
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </div>
  </v-container> 
</template>

<script>
import { httpClient } from '../services/http-client';
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'

export default {
  name: 'ReportStudentManagement',
  data () {
    return {
      headers: [
        {
          text: 'Student ID',
          align: 'left',
          value: 'StudentID'
        },
        { text: 'GivenName', value: 'GivenName' },
        { text: 'Surname', value: 'Surname' },
        { text: 'Email', value: 'Email' },
        { text: 'Enroled Year', value: 'EnroledYear' },
        { text: 'Region', value: 'RegionName' },
        { text: 'Campus', value: 'CentreName' },
        { text: 'Term', value: 'TermName' },
        { text: 'Homework Year', value: 'HomeworkYear' },
        { text: 'Homework Week', value: 'WeekID' },
        { text: 'Homework Subject', value: 'SubjectName' },
        { text: 'Number of files submitted', align: 'center', value: 'HomeworkCount' },
        { text: 'Number of files marked', align: 'center', value: 'MarkedCount' },
        { text: 'Number of awards given', align: 'center', value: 'AwardCount' },
      ],
      search: null,
      searchLoading: false,
      showTable: false,
      results: [],
      selectRegion: null,
      selectCentre: null,
      overlay: false,
      studentid: null,
      givenname: null,
      surname: null,
      totals: null,
    }
  },
  computed: {
    filteredCentres() {
      if (this.selectRegion)
        return this.centres.filter(c => c.RegionID === this.selectRegion);
      return this.centres;
    },
    ...mapGetters(['allowRegionFilter', 'allowCentreFilter']),
    ...mapState(['user', 'regions', 'centres'])
  },
  created () {
    this.initialize()
  },
  methods: {
    async initialize () {
      try {
        this.overlay = true;
        await Promise.all([
        this.$store.dispatch('getRegions'),
        this.$store.dispatch('getCentres')]);
      } finally {
        this.overlay = false;
      }
    },
    async Search() {
      try {
        this.showTable = true;
        this.searchLoading = true;
        const response = await httpClient.get('/report/studentmanagement', {
          params: {
            RegionID: this.selectRegion,
            CentreID: this.selectCentre,
            StudentID: this.studentid,
            GivenName: this.givenname,
            Surname: this.surname
          }
        });
        if (response && response.data) {
          this.results = response.data.Rows;
          this.totals = response.data.Totals;
        } else {
          this.results = [];
        }
      } finally {
        this.searchLoading = false;
      }
    },
  }
}
</script>

<style scoped></style>
